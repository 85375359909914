import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FindInListInterface } from '../tables/th/th.component';

@Component({
    selector: 'app-table-filters',
    templateUrl: './table-filters.component.html',
    styleUrls: ['./table-filters.component.scss']
})
export class TableFiltersComponent implements OnInit, OnDestroy
{
    @Input() id: string;
    @Input() label: string;
    @Input() model: unknown;
    @Input() loading?: boolean;
    @Input() items?: FindInListInterface[];
    @Input() placeholder?: string;
    @Input() disabled?: boolean;
    @Input() type: 'ngSelect' | 'select' | 'text' | 'money';
    @Input() multiple?: boolean = true;
    @Input() pattern?: string | RegExp;
    @Output() changeFilter = new EventEmitter();
    @Output() applyFilter = new EventEmitter();
    @ViewChild('form', {static: true, read: NgForm}) form: NgForm;
    valueFrom: string;
    valueTo: string;

    showAllOptionValue: any;
    static BooleanOptions = [
        { id: true, name: 'Yes' },
        { id: false, name: 'No' }
    ];
    readonly booleanOptions = TableFiltersComponent.BooleanOptions;
    private subscription: Subscription;

    constructor(private cd: ChangeDetectorRef)
    {
    }

    ngOnInit(): void
    {
        setTimeout(() => {
            this.showAllOptionValue = this.model;
            this.subscription = this.form.controls?.model?.valueChanges
                ?.pipe(debounceTime(60))
                ?.subscribe((data) => {
                    if (this.form.controls?.model?.errors) this.form.controls?.model.patchValue(data, { emitEvent: false });
                });
        });
    }

    ngOnDestroy()
    {
        this.subscription?.unsubscribe();
    }

    get isRange(): boolean
    {
        return this.type === 'money';
    }

    get canApplyFilter() {
        return (!this.isRange ||
            !this.valueFrom ||
            !this.valueTo ||
            (this.valueFrom && this.valueTo && this.valueFrom <= this.valueTo));
    }

    detectChanges(): void
    {
        this.cd.detectChanges();
    }

    getValidationClass(type: 'from' | 'to', form): string
    {
        switch (true) {
            case !form?.touched:
                return '';
            case !this.canApplyFilter || form?.controls[type]?.invalid:
                return 'is-invalid';
            case this.canApplyFilter || form?.controls[type]?.invalid:
                return 'is-valid';
            default:
                return '';
        }
    }

    clearFilter(): void
    {
        this.model = this.showAllOptionValue;
        this.valueFrom = '';
        this.valueTo = '';
        this.changeFilter.emit(this.model);
    }
}
